/// <reference path="./typing.d.ts">

$(function() {
    // TOP slider
    $('.js-slider').each(function(){
        $(this).slick();
    });

    
    // detail slider
    $('.js-jacket-slider').each(function(){
        var $slider = $(this);
        $slider.slick();

        var $thumbs = $('.js-jacket-thumb');
        $thumbs.on('click touch' , function(e) {
            e.preventDefault();

            var index = $thumbs.index(this);
            console.log(index);
            $slider.slick('slickGoTo', index);
        })
    });

    
    // tab
    $('.js-tab').tabslet();


    //sp menu trigger
    const ACTIVE_CLASS = "active";
    let $spNav = $('.sp-nav-list-wrap');
    $('.sp-nav-btn').on('touch click', function(e){
        e.preventDefault();

        $(this).toggleClass(ACTIVE_CLASS);
        $spNav.toggleClass(ACTIVE_CLASS);
    });


    //to top
    $('.to-top').on('touch click', function(e){
        e.preventDefault();

        $('body, html').animate({
            scrollTop: 0,
            duration: 400
        })
    });
});
